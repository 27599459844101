
import { animate,stagger, inView,timeline } from 'motion';
import baguetteBox  from './baguettebox.js';
//import { scrollTo } from './scrollTo.js';
import { toggleClass } from './mobileNavigation.js';
//import { initScrollUpBtn } from './scrollup.js';
import { InitNavigationMenu } from './navigation-menu-with-buttons.js';
import { ToggleNavigation } from "./mobileNavigation.js";
import { L } from "leaflet";



document.addEventListener("DOMContentLoaded", function(){
  'use strict';



  document.body.parentElement.classList.remove("no-js");



  baguetteBox.run('.ce_gallery, .ce_image a[data-lightbox], .ce_text a[data-lightbox],.product-gallery a[data-lightbox] ', {
    animation: 'fadeIn',
    noScrollbars: false
  });

  if(window.innerWidth < 722) {

    new ToggleNavigation("#mobile-trigger","#mobile-trigger");

  }
  const navigationMenu = new InitNavigationMenu();

  document.querySelector("#search-trigger").addEventListener("click",(event) => {
    document.querySelector("#header .mod_search").classList.toggle("show");
    document.querySelector("#header-icons").classList.toggle("search-active");
  });


  const animatedBoxes = document.querySelectorAll(".animated-boxes");
  inView(animatedBoxes, (boxes) => {
    animate(boxes.target.parentElement.querySelectorAll(".animated-boxes > *"),{
      opacity: [0,1],
      translateY: ["10%",0]
    },{
      translateY: {
        delay: stagger(0.75),
        duration: 2,
        easing: [0.54, 0.9, 0.71, 1],
      },
      opacity: {
          delay: stagger(1),
          duration: 1.75,
          easing: [0.54, 0.9, 0.71, 1]
      },

    });
  });

  initializeMap();
});



//initScrollUpBtn();
